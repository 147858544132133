/* eslint-disable no-unused-vars */
declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    hj(event: "event", event_name: string): void;
    hj(
      event: "identify",
      user_id: string,
      user_properties: Record<string, any>
    ): void;
    hsConversationsSettings: any;
    HubSpotConversations: any;
    __tt_preload?: {
      setting?: any;
      performance?: any;
    };
  }
}

export type IsoDate = `${number}-${number}-${number}`;

export const FeatureFlag = {
  PROMO_CODES: "promo_codes",
  STANDING_ROOM: "standing_room",
  ENFORCE_MINIMUMS: "enforce_minimums",
  FEE_DISCLOSURE: "fee_disclosure",
  TERMINAL: "terminal",
  SKIP_SEATING_TIME: "skip_seating_time",
  LIVESTREAM: "livestream",
} as const;
export type FeatureFlagType = (typeof FeatureFlag)[keyof typeof FeatureFlag];

export const ReservationStatus = {
  UNPAID: "unpaid",
  EXPECTED: "expected",
  ARRIVED: "arrived",
  SEATED: "seated",
  NO_SHOW: "no show",
  CANCELED: "canceled",
  CLEARED: "cleared",
  OBSOLETE: "obsolete",
  PAY_LATER: "paylater",
  FAILED: "failed",
} as const;
export type ReservationStatusType =
  (typeof ReservationStatus)[keyof typeof ReservationStatus];

export const UserGroup = {
  MANAGERS: "managers",
  OWNERS: "owners",
  REFUNDERS: "refunders",
} as const;
export type UserGroupType = (typeof UserGroup)[keyof typeof UserGroup];

export const TableShape = {
  CIRCLE: "circle",
  SQUARE: "square",
  TRIANGLE: "triangle",
  HALF_CIRCLE: "half-circle",
  PIANO: "piano",
} as const;
export type TableShapeType = (typeof TableShape)[keyof typeof TableShape];

export const EmailStatus = {
  NEW: "new",
  GOOD: "good",
  SPAM: "spam",
  BOUNCED: "bounced",
} as const;
export type EmailStatusType = (typeof EmailStatus)[keyof typeof EmailStatus];

export type ReportStatusType =
  (typeof REPORT_STATUS)[keyof typeof REPORT_STATUS];
export const REPORT_STATUS = {
  PENDING: "pending",
  WORKING: "working",
  COMPLETE: "complete",
  FAILED: "failed",
};

export type BoolParam = "true" | "false";
export interface PerformanceQueryParams {
  page?: number;
  page_size?: number;
  start_date?: string;
  end_date?: string;
  date?: string;
  show?: number;
  pagination?: BoolParam;
  booking?: BoolParam;
}

export interface GuestExportArgs {
  accepts_marketing?: BoolParam;
  no_accepts_marketing?: BoolParam;
}

export interface ReservationExportArgs {
  start_date?: IsoDate;
  end_date?: IsoDate;
  show_start_date?: IsoDate;
  show_end_date?: IsoDate;
  residency_id?: number;
  performances?: number[];
}

export type ReportArgs = GuestExportArgs | ReservationExportArgs;

export type StripeLocationId = `tml_${string}`;
export type StripeReaderId = `tmr_${string}`;
export type StripeReaderStatus = "offline" | "online";
export type StripeReaderOutcome = "succeeded" | "failed" | "in_progress";

interface Flavoring<FlavorT> {
  _type?: FlavorT;
}
export type TypedId<FlavorT> = number & Flavoring<FlavorT>;

export interface SuccessResponse {
  success: true;
}
// end of static_types.ts
export type AddOnId = TypedId<'AddOn'>
export type ArrangementId = TypedId<'Arrangement'>
export type CouponId = TypedId<'Coupon'>
export type CustomerId = TypedId<'Customer'>
export type GuestTagId = TypedId<'GuestTag'>
export type InviteCodeId = TypedId<'InviteCode'>
export type OrderPricingId = TypedId<'OrderPricing'>
export type PerformanceId = TypedId<'Performance'>
export type PerformanceAddOnId = TypedId<'PerformanceAddOn'>
export type PerformanceSeatingTimeId = TypedId<'PerformanceSeatingTime'>
export type PerformanceTableId = TypedId<'PerformanceTable'>
export type PromotionCodeId = TypedId<'PromotionCode'>
export type ReportInstanceId = TypedId<'ReportInstance'>
export type ReservationId = TypedId<'Reservation'>
export type ReservationAddOnId = TypedId<'ReservationAddOn'>
export type ReservationStatusId = TypedId<'ReservationStatus'>
export type ReservationTagId = TypedId<'ReservationTag'>
export type ResidencyId = TypedId<'Residency'>
export type SeatingTimeId = TypedId<'SeatingTime'>
export type ShowId = TypedId<'Show'>
export type ShowTimeId = TypedId<'ShowTime'>
export type TableId = TypedId<'Table'>
export type TableTypeId = TypedId<'TableType'>
export type TransactionId = TypedId<'Transaction'>
export type UserId = TypedId<'User'>
export interface AccountingReportRead {
    month: string;
    face: number;
    add_ons: number;
    discounts: number;
    refunds: number;
    service_fee: number;
    ticket_tax: number;
    add_ons_tax: number;
    stripe_fees: number;
    application_fees: number;
    guests_sum: number;
}

export interface AddOnRead {
    id: AddOnId;
    name: string;
    price: number;
    description: string;
    tax_rate: number;
}

export interface AddOnWrite {
    id?: AddOnId;
    name?: string;
    price: number;
    description: string;
    tax_rate?: number;
}

export interface ArrangementRead {
    id: ArrangementId;
    name: string;
    tables: TableRead[];
    is_primary: boolean;
}

export interface ArrangementWrite {
    id?: ArrangementId;
    name?: string;
    is_primary?: boolean;
}

export interface BulkPriceWrite {
    tables: number[];
    price: number;
}

export interface CouponRead {
    id: CouponId;
    name: string;
    percent_off: number;
    max_redemptions: number;
    redeem_by: string;
    terminal_eligible: boolean;
}

export interface CouponWrite {
    id?: CouponId;
    name?: string;
    percent_off?: number;
    max_redemptions: number;
    redeem_by: string;
    terminal_eligible?: boolean;
}

export interface FeaturesRead {
    promo_codes: boolean;
    standing_room: boolean;
    enforce_minimums: boolean;
    fee_disclosure: boolean;
    terminal: boolean;
    skip_seating_time: boolean;
    livestream: boolean;
}

export interface FloorPlanRead {
    id: ArrangementId;
    name: string;
    tables: FloorPlanTableRead[];
    is_primary: boolean;
}

export interface FloorPlanTableRead {
    id: TableId;
    name: string;
    minimum_guests: number;
    maximum_guests: number;
    x: number;
    y: number;
    w: number;
    h: number;
    rotation: number;
    held: boolean;
    default_price_per_person: number;
    shape: TableShapeType;
    type_id: TableTypeId;
    standing_room: boolean;
}

export interface FloorPlanTableWrite {
    id?: TableId;
    name?: string;
    minimum_guests: number;
    maximum_guests: number;
    x: number;
    y: number;
    w?: number;
    h?: number;
    rotation?: number;
    held?: boolean;
    default_price_per_person?: number;
    shape?: TableShapeType;
    type_id?: TableTypeId;
    standing_room?: boolean;
}

export interface FloorPlanWrite {
    id?: ArrangementId;
    name?: string;
    tables: FloorPlanTableWrite[];
    is_primary?: boolean;
}

export interface GuestDetailedRead {
    id: CustomerId;
    stripe_id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    accepts_marketing: boolean;
    reservations: ReservationGuestDetailedRead[];
    tags: GuestTagId[];
}

export interface GuestRead {
    id: CustomerId;
    stripe_id: string;
    first_name: string;
    last_name: string;
    name: string;
    email: string;
    email_status: EmailStatusType;
    tags: GuestTagId[];
    phone: string;
    notes: string;
}

export interface GuestSearchRead {
    id: CustomerId;
    stripe_id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    accepts_marketing: boolean;
    transaction_count: number;
}

export interface GuestTagRead {
    id: GuestTagId;
    name: string;
    icon_url: string;
}

export interface GuestWrite {
    id?: CustomerId;
    stripe_id?: string;
    first_name?: string;
    last_name?: string;
    email: string;
    tags?: GuestTagId[];
    phone?: string;
    notes?: string;
}

export interface ImageRead {
    src: string;
    size: string;
}

export interface InviteCodeRead {
    id: InviteCodeId;
    code: string;
    max_redemptions: number;
}

export interface InviteCodeWrite {
    id?: InviteCodeId;
    code: string;
    max_redemptions: number;
}

export interface MailchimpSettingsRead {
    connected: any;
    audience_id: string | null;
}

export interface MailchimpSettingsWrite {
    audience_id?: string | null;
}

export interface PerformanceAddOnRead {
    id: PerformanceAddOnId;
    parent_id: AddOnId | null;
    is_active: boolean;
    name: string;
    description: string;
    tax_rate: number;
    price: number;
}

export interface PerformanceAddOnWrite {
    id?: PerformanceAddOnId;
    parent_id?: AddOnId | null;
    is_active?: boolean;
    name?: string;
    description: string;
    tax_rate?: number;
    price: number;
}

export interface PerformanceCreate {
    datetime: string;
    publish_datetime?: string | null;
    on_sale_datetime?: string | null;
    off_sale_datetime?: string | null;
    show_id: ShowId;
    arrangement_id: ArrangementId;
    showtime_id: ShowTimeId;
    add_on_ids?: AddOnId[];
}

export interface PerformanceDetailedWrite {
    id?: PerformanceId;
    datetime: string;
    publish_datetime?: string | null;
    on_sale_datetime?: string | null;
    off_sale_datetime?: string | null;
    extra_template_text?: string;
    show_id?: ShowId;
    tables: PerformanceTableWrite[];
    add_ons: PerformanceAddOnWrite[];
    seating_times: PerformanceSeatingTimeWrite[];
    invite_codes?: InviteCodeWrite[];
}

export interface PerformanceListRead {
    id: PerformanceId;
    datetime: string;
    publish_datetime: string | null;
    on_sale_datetime: string | null;
    off_sale_datetime: string | null;
    created: string;
    show_id: any;
    floor_plan_id: any;
    show: ShowRead;
    sold: boolean;
    residencies: ResidencyListRead[];
}

export interface PerformanceRead {
    id: PerformanceId;
    datetime: string;
    publish_datetime: string | null;
    on_sale_datetime: string | null;
    off_sale_datetime: string | null;
    created: string;
    show_id: ShowId;
    floor_plan_id: number;
    show: ShowRead;
    sold: boolean;
    tables: PerformanceTableRead[];
    add_ons: PerformanceAddOnRead[];
    seating_times: PerformanceSeatingTimeRead[];
}

export interface PerformanceReportRead {
    id: PerformanceId;
    datetime: string;
    publish_datetime: string | null;
    on_sale_datetime: string | null;
    off_sale_datetime: string | null;
    created: string;
    show_id: any;
    floor_plan_id: any;
    show: ShowRead;
    sold: boolean;
    booked_tables: number;
    table_count: number;
    booked_guests: number;
    amount_sum: number;
    add_on_amount_sum: number;
    tax_sum: number;
    service_fee_sum: number;
    discounted_sum: number;
    refunded_sum: number;
    net_face: number;
}

export interface PerformanceReservationsRead {
    id: PerformanceId;
    datetime: string;
    publish_datetime: string;
    on_sale_datetime: string;
    off_sale_datetime: string | null;
    created: string;
    show_id: any;
    floor_plan_id: any;
    show: ShowRead;
    sold: boolean;
    tables: PerformanceTableRead[];
    reservations: ReservationRead[];
    seating_times: PerformanceSeatingTimeRead[];
    add_ons: PerformanceAddOnRead[];
    invite_codes: InviteCodeRead[];
    extra_template_text: string;
}

export interface PerformanceSeatingTimeRead {
    id: PerformanceSeatingTimeId;
    time: string;
    maximum_guests: number;
    reserved_guests: number;
}

export interface PerformanceSeatingTimeWrite {
    id?: PerformanceSeatingTimeId;
    time: string;
    maximum_guests: number;
}

export interface PerformanceTableRead {
    id: PerformanceTableId;
    name: string;
    minimum_guests: number;
    maximum_guests: number;
    x: number;
    y: number;
    w: number;
    h: number;
    rotation: number;
    held: boolean;
    price_per_person: number;
    shape: TableShapeType;
    performance: any;
    type_id: TableTypeId;
    standing_room: boolean;
    available_guests: number;
    available: boolean;
}

export interface PerformanceTableWrite {
    id?: PerformanceTableId;
    name?: string;
    minimum_guests: number;
    maximum_guests: number;
    x: number;
    y: number;
    w?: number;
    h?: number;
    rotation?: number;
    held?: boolean;
    price_per_person?: number;
    shape?: TableShapeType;
    performance: any;
    type_id?: TableTypeId;
    standing_room?: boolean;
}

export interface PerformanceWrite {
    id?: PerformanceId;
    datetime: string;
    publish_datetime?: string | null;
    on_sale_datetime?: string | null;
    off_sale_datetime?: string | null;
    show_id?: ShowId;
    seating_times?: PerformanceSeatingTimeWrite[];
}

export interface PromotionCodeRead {
    name: string;
    coupon: CouponRead;
    code: string;
    active: boolean;
    expires_at: string;
    max_redemptions: number;
}

export interface PromotionCodeWrite {
    name?: string;
    coupon: CouponWrite;
    code: string;
    active: boolean;
    expires_at: string;
    max_redemptions: number;
}

export interface ReportInstanceRead {
    id: ReportInstanceId;
    created: string;
    status: ReportStatusType;
    name: string;
    report: "guest" | "transaction" | "reservation";
    args: ReportArgs;
    user_id: any;
}

export interface ReportInstanceWrite {
    id?: ReportInstanceId;
    name?: string;
    report?: "guest" | "transaction" | "reservation";
    args?: ReportArgs;
}

export interface ReservationAddOnRead {
    performance_add_on_id: PerformanceAddOnId;
    quantity: number;
    price: number;
}

export interface ReservationAddOnWrite {
    performance_add_on_id: PerformanceAddOnId;
    quantity: number;
}

export interface ReservationCreate {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    accepts_marketing?: boolean;
    performance: number;
    number_of_guests: number;
    tables: number[];
    seating_time: number;
    promo_code?: string;
    coupon_id?: CouponId;
    invite_code?: string;
    guest_notes?: string;
    internal_notes?: string;
    add_ons?: ReservationAddOnWrite[];
    status?: "unpaid" | "expected" | "arrived" | "seated" | "no show" | "canceled" | "cleared" | "failed" | "obsolete" | "paylater";
    reader_id?: StripeReaderId;
    amount?: string;
    add_on_amount?: string;
    service_fee?: string;
    tax?: string;
    discounted?: string;
    tags?: ReservationTagId[];
}

export interface ReservationDetailedRead {
    id: ReservationId;
    created: string;
    user_id: UserId;
    contact: GuestRead;
    seating_time_id: PerformanceSeatingTimeId;
    performance: PerformanceListRead;
    performance_id: PerformanceId;
    transactions: TransactionDetailedRead[];
    tables: PerformanceTableId[];
    res_tables: ReservationTableDetailedRead[];
    number_of_guests: number;
    guest_notes: string;
    internal_notes: string;
    amount: number;
    add_on_amount: number;
    tax: number;
    service_fee: number;
    refunded: number | null;
    discounted: number;
    status: ReservationStatusType;
    promotion_code: PromotionCodeRead;
    add_ons: ReservationAddOnRead[];
    tags: ReservationTagId[];
}

export interface ReservationGuestDetailedRead {
    id: ReservationId;
    user_id: UserId;
    seating_time_id: PerformanceSeatingTimeId;
    performance: PerformanceListRead;
    performance_id: PerformanceId;
    transactions: TransactionDetailedRead[];
    tables: PerformanceTableId[];
    number_of_guests: number;
    guest_notes: string;
    internal_notes: string;
    amount: number;
    tax: number;
    status: ReservationStatusType;
    service_fee: number;
}

export interface ReservationListRead {
    id: ReservationId;
    created: string;
    contact: GuestRead;
    performance_id: PerformanceId;
    performance: PerformanceListRead;
}

export interface ReservationPaidUpdate {
    id?: ReservationId;
    status?: ReservationStatusType;
    seating_time: any;
    performance_id?: PerformanceId;
    tables: any[];
    tags?: ReservationTagId[];
    guest?: GuestWrite;
    number_of_guests: number;
    guest_notes?: string;
    internal_notes?: string;
    add_ons?: ReservationAddOnWrite[];
    reader_id: StripeReaderId;
}

export interface ReservationRead {
    id: ReservationId;
    created: string;
    user: any | null;
    user_id: UserId;
    contact: GuestRead;
    seating_time_id: PerformanceSeatingTimeId;
    seating_time: any;
    performance_id: PerformanceId;
    performance: any;
    tables: PerformanceTableId[];
    number_of_guests: number;
    fee: number;
    guest_notes: string;
    internal_notes: string;
    amount: number;
    add_on_amount: number;
    tax: number;
    service_fee: number;
    refunded: number | null;
    discounted: number;
    status: ReservationStatusType;
    promotion_code: PromotionCodeRead;
    add_ons: ReservationAddOnRead[];
    tags: ReservationTagId[];
    coupon: CouponRead;
}

export interface ReservationTableDetailedRead {
    performancetable_id: any;
    price_per_person: number | null;
    guests: number | null;
}

export interface ReservationTagRead {
    id: ReservationTagId;
    name: string;
    icon_url: string;
}

export interface ReservationUpdate {
    id?: ReservationId;
    status?: ReservationStatusType;
    seating_time: any;
    performance_id?: PerformanceId;
    tables: any[];
    tags?: ReservationTagId[];
    guest?: GuestWrite;
    number_of_guests: number;
    guest_notes?: string;
    internal_notes?: string;
    add_ons?: ReservationAddOnWrite[];
}

export interface ResidencyListRead {
    id: ResidencyId;
    name: string;
    description: string;
    slug: string;
}

export interface ResidencyRead {
    id: ResidencyId;
    name: string;
    description: string;
    slug: string;
    start_date: IsoDate;
    end_date: IsoDate;
    performances: PerformanceListRead[];
    performance_ids: PerformanceId[];
    show_id: ShowId;
    show: ShowRead;
}

export interface ResidencyWrite {
    id?: ResidencyId;
    name?: string;
    description?: string;
    performance_ids: PerformanceId[];
    show_id?: ShowId;
}

export interface SeatingTimeRead {
    id: SeatingTimeId;
    time: string;
    maximum_guests: number;
    reserved_guests: number;
}

export interface SeatingTimeWrite {
    id?: SeatingTimeId;
    time: string;
    maximum_guests: number;
}

export interface SettingRead {
    background_path: any;
    dark_background_path: any;
    checkout_confirmation_text: string;
    checkout_text: string;
    company_id: any;
    features: FeaturesRead;
    guest_tags: GuestTagRead[];
    link_url: string;
    logo_url: string;
    css_url: string;
    max_guests: number;
    public_key: any;
    ratio: number;
    reply_to: string;
    reservation_tags: ReservationTagRead[];
    seating_time_text: string;
    service_fee: number;
    service_percentage: number;
    stripe_account: string;
    stripe_charges_enabled: boolean;
    table_types: TableTypeRead[];
    tax_rate: number;
    fee_tax_rate: number;
    terminal_service_fee: number;
    terminal_service_percentage: number;
    application_fee: number;
    application_fee_per_reservation: number;
    timezone: any;
    venue_currency: string;
    venue_locale: string;
    venue_id: any;
    venue_name: string;
    venue_slug: string;
}

export interface SettingWrite {
    service_fee?: number;
    service_percentage?: number;
    terminal_service_fee?: number;
    terminal_service_percentage?: number;
    reply_to?: string;
    link_url?: string;
    tax_rate?: number;
    fee_tax_rate?: number;
    timezone?: string;
    max_guests?: number;
}

export interface ShowRead {
    id: ShowId;
    name: string;
    image: string;
    srcset: ShowSrcsetRead;
    price_per_person: number[] | null;
    position: number[];
    description: string;
}

export interface ShowSrcsetRead {
    sqSm: ImageRead;
    sqLg: ImageRead;
    rectSm: ImageRead;
    rectLg: ImageRead;
    original: ImageRead;
}

export interface ShowTimeRead {
    id: ShowTimeId;
    name: string;
    time: string;
    seating_times: ShowTimeSeatingTimeRead[];
}

export interface ShowTimeSeatingTimeRead {
    time: string;
    maximum_guests: number;
}

export interface ShowTimeSeatingTimeWrite {
    time: string;
    maximum_guests: number;
}

export interface ShowTimeWrite {
    id?: ShowTimeId;
    name?: string;
    time: string;
    seating_times?: ShowTimeSeatingTimeWrite[];
}

export interface ShowWrite {
    id?: ShowId;
    name?: string;
    price_per_person?: number[] | null;
    position?: number[];
    description?: string;
}

export interface StripeLocationRead {
    id: StripeLocationId;
    display_name: string;
}

export interface StripeReaderActionRead {
    failure_code: string;
    failure_message: string;
    payment_intent_id: string;
    status: StripeReaderOutcome;
    type: string;
}

export interface StripeReaderMessageRead {
    status: StripeReaderOutcome;
    reader_id: StripeReaderId;
    failure_code: string;
    failure_message: string;
    payment_intent_id: string;
}

export interface StripeReaderRead {
    id: StripeReaderId;
    label: string;
    action: StripeReaderActionRead;
    location_id: StripeLocationId;
    status: StripeReaderStatus;
    device_type: string;
}

export interface SummaryReportParameters {
    start: IsoDate;
    end: IsoDate;
}

export interface SummaryReportRead {
    face: number;
    add_ons: number;
    discounts: number;
    refunds: number;
    service_fee: number;
    ticket_tax: number;
    add_ons_tax: number;
    stripe_fees: number;
    application_fees: number;
    guests_sum: number;
}

export interface TableRead {
    id: TableId;
    name: string;
    minimum_guests: number;
    maximum_guests: number;
    x: number;
    y: number;
    w: number;
    h: number;
    rotation: number;
    held: boolean;
    default_price_per_person: number;
    shape: TableShapeType;
    type_id: TableTypeId;
    standing_room: boolean;
    arrangement: any;
}

export interface TableTypeRead {
    id: TableTypeId;
    name: string;
    abbreviation: string;
}

export interface TableTypeWrite {
    id?: TableTypeId;
    name?: string;
    abbreviation?: string;
}

export interface TableWrite {
    id?: TableId;
    name?: string;
    minimum_guests: number;
    maximum_guests: number;
    x: number;
    y: number;
    w?: number;
    h?: number;
    rotation?: number;
    held?: boolean;
    default_price_per_person?: number;
    shape?: TableShapeType;
    type_id?: TableTypeId;
    standing_room?: boolean;
    arrangement: any;
}

export interface TransactionDetailedRead {
    id: TransactionId;
    created: string;
    user_id: any;
    reservation_id: any;
    client_secret: string;
    payment_intent_id: any;
    number_of_guests: number | null;
    amount: number;
    add_on_amount: number;
    tax: number;
    service_fee: number;
    discounted: number;
    refunded: number | null;
    paid: boolean;
    application_fee: number | null;
    stripe_fee: number | null;
}

export interface TransactionRead {
    id: TransactionId;
    user: any | null;
    customer: any | null;
    reservation: any;
    timezone: number;
    client_secret: string;
    amount: number;
    add_on_amount: number;
    tax: number;
    service_fee: number;
    refunded: number | null;
    discounted: number;
    paid: boolean;
    application_fee: number | null;
    stripe_fee: number | null;
}

export interface TransactionWrite {
    id?: TransactionId;
    user?: any | null;
    customer?: any | null;
    reservation: any;
    timezone?: number;
    client_secret?: string;
    amount?: number;
    add_on_amount?: number;
    tax?: number;
    service_fee?: number;
    refunded?: number | null;
    discounted?: number;
    paid?: boolean;
    application_fee?: number | null;
    stripe_fee?: number | null;
}

export interface UserRead {
    id: UserId;
    email: string;
    first_name: string;
    last_name: string;
    phone: string | null;
    accepts_marketing: boolean;
    venues: UserVenueRead[];
    hubspot_token: any;
    groups: UserGroupType[];
    last_login: string | null;
    is_active: boolean;
}

export interface UserVenueRead {
    name: string;
    primary_site: string;
}

export interface UserWrite {
    id?: UserId;
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string | null;
    accepts_marketing?: boolean;
    groups: UserGroupType[];
    is_active?: boolean;
}

